.query {
    padding: 20px;
    border-radius: 20px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    color: #4f4b48;
    text-decoration: none;
    transition: .2s ease-in;
}

.f4{
    background-color: rgba(131, 206, 241, 0.5);
    box-shadow: 5px 10px 8px 10px #888888;
}
@media(min-width: 990px) {
   .f4{
       width: 50%;
   } 
}