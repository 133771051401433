.services {
    text-align: center;
    margin-top: 4em;
}

.services h2 {
    color: rgba(0, 0, 0, 0.651);
}

.services-container {
    margin-top: 5rem;
}

.service {
    padding: 20px;
    border-radius: 20px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    color: #4f4b48;
    text-decoration: none;
    transition: .2s ease-in;
}

.service > img  {
    margin-bottom: 10px;
}

.zone {
    cursor:pointer;
    display:inline-block;
    text-align: center;
    font-size:1.3em;
    border-radius:15px;
    transition: all 0.3s linear;
    padding: 20px;
}

.service:hover {
    color: #4f4b48;
    transform: scale(1.1);
    -webkit-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px;
    -moz-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px;
    -o-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px;
    box-shadow:rgba(0, 0, 0, 0.5) 0px 5px 10px;
}

