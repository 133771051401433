.fl{
    display:flex;
    padding-top:10px;
    padding-bottom:10px;
    flex-direction:row;
    background-color: rgba(247, 248, 252, 0.253);
    /* box-shadow: 0 0 5px 5px rgb(255, 136, 0); */
    box-shadow: 5px 10px 8px 10px #888888;
    border-radius: 10px;
    /* border: black 2px solid; */
    
}
.r{
    margin-bottom: 25px;
}
.reviews {
    padding: 20px;
    border-radius: 20px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    color: #4f4b48;
    text-decoration: none;
    transition: .2s ease-in;
}

@media screen and (max-width: 650px){
    
    .r1{
          width:100vw;
      
    }
    
  }
  @media screen and (min-width: 651px){
     
     .r1{
         width:45vw;
         margin-left: 13%;
        
   }
    .r{
        /* height: 45vh; */
        padding-top: 10vh;
        padding-bottom: 20px;
        background-image: url("../../assets/images/rev.jpg");
        background-size: cover;
        /* background-color: rgba(202, 233, 29, 0.726); */
        padding-left: 20%;
        padding-right: 20%;
    }
   
   }
   
   

