.background {
    background: url("../../assets/images/contact2.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.icons {
    margin-top: 4em;
    margin-bottom: 4em;
}

.container-contact { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px; 
}

.google-map {
    background-color: rgba(1,1,1,0.3);
    padding: 20px;
    height: 500px;
}

.flex-container {
    display: flex;
    list-style: none;
    /*padding-top: 3em*/
    margin: 50px;
    font-size: 0.7em;
    padding-top: 100px;
    padding-bottom: 30px; 
}


h1 { 
    font-size: 2.5em;
    text-align: center;
    color: white;
}

.text-background{
    width: fit-content;
    padding: 15px;
    text-align: center;
    color: white;
    background-color: rgba(0,0,0,0.3);
    border-radius:40px;
}

.icons > div > a > img {
    transition: 0.1s ease-in;
}

@media(max-width: 500px) {
    .icons > div > a > img {
        height: 60px;
        width: 60px;
    }
}

.icons > div > a > img:hover {
   transform: scale(1.08);
}

.address > h2 {
    color: rgb(1, 116, 197);
}

.address > p {
    font-size: 1.1em;
}

.address > p > span {
    font-weight: 700;
}