.program-heading {
    margin-bottom: 2.5em;
    padding-top: 2em;
    padding-bottom: 2.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.137);
}

.program-heading h1{
    color: rgb(1, 116, 197);
    /* text-transform: uppercase; */
    font-weight: 600;
}

.slide-img {
    max-height: 550px;
}

@media (max-width: 500px) {
    .slide-img {
        height: 200px;
    }

    .program-text {
        /* font-size: 0.5em; */
        padding: 0;
    }

    .program-text p {
        font-size: 1.2em!important;
    }

    .program-heading h1 {
        font-size: 1.9em;
    }
}