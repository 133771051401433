@media (max-width: 500px) {
    .mimg {
        height: 320px;
        margin-left: 8%;
        width: 75%;
    }
    .name{
        margin-left: 2%;
        font-size: 20px; 
    }

    .program-text {
        /* font-size: 0.5em; */
        padding-left: 5%;
    }

    .program-text p {
        font-size: 1.2em!important;
    }
    .an{
        padding-left: 18px;
    }
}
.mimg {
    max-height: 510px;
    box-shadow: 0 0 20px -2px rgba(0, 0, 0, 0.404);
    border-radius: 5px;

}
.m12{
    padding-left: 12%;
}

.program-heading {
    margin-bottom: 2.5em;
    padding-top: 2em;
    padding-bottom: 2.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.137);
}

.program-heading h1{
    color: rgb(1, 116, 197);
    /* text-transform: uppercase; */
    font-weight: 600;
}

.service-card {
    padding-top: 2em;
    padding-bottom: 2em;
}
.row{
    margin-left: 0;
    margin-right: 0;
}
.right-text1{
    padding-right: 2;
    padding-left: 0;
}


