.services-heading {
    margin-bottom: 3em;
    padding-top: 2em;
    padding-bottom: 3em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.137);
}

.services-heading h1{
    color: rgb(1, 116, 197);
    /* text-transform: uppercase; */
    font-weight: 600;
}

.service-card {
    padding-top: 6em;
    padding-bottom: 2em;
}

.service-card::before {
    content: "";
    display: block;
    height: 60px; /* fixed header height*/
    margin: -60px 0 0; /* negative fixed header height */
  }

.service-card:nth-of-type(odd) {
    display: flex !important;
    flex-direction: row-reverse;
}

@media(min-width: 990px) {
    .service-card:nth-of-type(odd) > .right-text {
        padding-right: 1%;
        padding-left: 5%;
    }
    .im{
        height: 425px;
    }
    .im1{
        height: 375px;
    }
}

.img-rounded {
    box-shadow: 0 0 20px -2px rgba(0, 0, 0, 0.404);
    border-radius: 5px
}

@media(min-width: 990px) {
    .right-text {
        padding-left: 5%;
        padding-right: 1%;
    }
}

.right-text h2 {
    color: #242221;
    margin-bottom: 28px;
    margin-top: 0px;
}

.right-text p {
    color: #2422219f;
}
