.main-img {
    height: 90vh;
    background-size: cover;
}

@media (max-width: 500px) {
    .main-img {
        /* margin-left: -50px; */
        /* height: 500px; */
        background-position: -370px;
    }
    .main-top-text {
        margin-bottom: 0!important;
        
    }
    .main-top-text h1 {
        font-size: 1.8em;
    }
}

.main-top-text {
    color: white;
}

.objective-container {
    margin-top: 70px;
}

.intro-text-2 {
    margin-top: 5em;
}

.slider-size {
    height: 800px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0%;
}

.col-md-4 h4 {
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 1.5em;
}

.address ul {
    text-decoration: none;
    list-style-type: none;
    padding: 0;
}

.address .icon {
    margin: 2px;
}

td.text {
    padding-bottom: 10px;
}

td.icon {
    padding-right: 15px;
}

.text a {
    color: #ffffffe6;
}

.email-form {
    background-color: rgb(206, 232, 255);
}
