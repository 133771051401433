.navbar {
    background-color: white;
}

.top-icons {
    font-size: 2em;
    color: #4f4b48;
}

.top-icons:hover {
    color: rgb(1, 116, 197);
}

.nav-links > li{
    width: max-content;
}

.nav-links > li > a:hover {
    font-weight: 600;
    color: rgb(1, 116, 197) !important;
}

.nav-links > li > a {
    color: #4f4b48 !important;
    font-size: 1.5em;
}